import React from "react";

export function fancyError(error) {
  // eslint-disable-next-line no-console
  console.error(`%c ${error} `, `background: #E83B46; color: #280001`);
}

export function fancyLog(log) {
  // eslint-disable-next-line no-console
  console.log(`%c ${log} `, `background: #BDC6CF; color: #242424`);
}

export function fancyWarning(warning) {
  // eslint-disable-next-line no-console
  console.warn(`%c ${warning} `, `background: #FEDCA2; color: #332A03`);
}

export function getRandomIntByRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function preloadImages(images) {
  if (!preloadImages.list) {
    preloadImages.list = [];
  }

  const { list } = preloadImages;

  for (let i = 0; i < images.length; i += 1) {
    const img = new Image();

    img.onload = () => {
      const index = list.indexOf(this);

      if (index !== -1) {
        list.splice(index, 1);
      }
    };

    list.push(img);

    img.src = images[i];
  }
}

export function shuffleArray(array) {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function splitStringToParagraphs(string, marginRems = 2) {
  const splitText = string.split(/\r?\n/);
  const jsx = [];

  splitText.forEach((row, index) => {
    const rowIndex = index;

    if (row !== ``) {
      jsx.push(
        <p
          key={`split-text-${rowIndex}`}
          className={`relative block mb-${marginRems}`}
        >
          <span>{row}</span>
        </p>
      );
    }
  });

  return jsx;
}

export function splitStringToSpans(string, className = ``) {
  const splitText = string.split(/\r?\n/);
  const jsx = [];

  splitText.forEach((row, index) => {
    const rowIndex = index;

    if (row !== ``) {
      jsx.push(
        <span key={`split-text-${rowIndex}`} className={className}>
          {row}
        </span>
      );
    }
  });

  return jsx;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
}
export const sortNodes = (a, b) => {
  if (!a.node.frontmatter?.date || !b.node.frontmatter?.date) {
    return false;
  }

  const [day1, month1, year1] = a.node.frontmatter.date.split(`/`);
  const date1 = new Date(parseInt(year1), parseInt(month1) - 1, parseInt(day1));
  const [day2, month2, year2] = b.node.frontmatter.date.split(`/`);
  const date2 = new Date(parseInt(year2), parseInt(month2) - 1, parseInt(day2));

  return date2 - date1;
};

export const getSortedArtists = data => {
  return data.allMarkdownRemark.edges.sort(sortNodes).map(({ node }) => node);
};

export const getAlignClass = alignment => {
  switch (alignment) {
    case `right`:
      return `justify-end`;
    default:
      return ``;
  }
};
//

export const getWidthClass = width => {
  switch (width) {
    case `medium`:
      return `w-3/5 sm:w-full`;
    case `small`:
      return `w-1/2 sm:w-full`;
    default:
      return `w-full`;
  }
};
