import React from "react";
import PropTypes from "prop-types";
import Footer from "~components/Footer";

const FooterClipped = ({ color }) => (
  <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 z-50 clipped overflow-hidden pointer-events-none">
    <Footer clipped color={color} />
  </div>
);

FooterClipped.defaultProps = {
  color: `white`
};

FooterClipped.propTypes = {
  color: PropTypes.string
};

export default FooterClipped;
