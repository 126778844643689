/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import HeaderClipped from "~components/HeaderClipped";
import FooterClipped from "~components/FooterClipped";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import { getSortedArtists, getAlignClass, getWidthClass } from "~utils/helpers";

const IndexPage = ({ data, location }) => {
  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;

  const artists = getSortedArtists(data);
  const artistGallery = {};

  artists.forEach(artist => {
    const { gallery, role, title } = artist.frontmatter;
    const { slug } = artist.fields;

    if (gallery) {
      artistGallery[title] = {
        gallery,
        role,
        slug
      };
    }
  });

  const artistGalleryKeys = Object.keys(artistGallery);

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="index-page w-full relative flex flex-col justify-between pt-32 pb-32 bg-white">
        <HeaderClipped />
        <FooterClipped />

        <section className="w-full relative grid">
          {artistGalleryKeys.length > 0 &&
            artistGalleryKeys.map((key, artistIndex) => {
              const artist = artistGallery[key];
              const previewImage = artist.gallery.find(item => item.image);
              const animationClass =
                artistIndex < 5
                  ? `animation-appear animation-delay-${artistIndex + 1}`
                  : ``;

              const alignClass = getAlignClass(previewImage.align);
              const widthClass = getWidthClass(previewImage.width);

              return (
                <article
                  key={key}
                  className={`index-page__artist ${animationClass} grid-end-6 md:grid-end-8 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 relative flex flex-col ${alignClass}`}
                >
                  <Link
                    to={artist.slug}
                    className={`${widthClass} relative block mb-16 sm:mb-4`}
                  >
                    <div className="image-overlay">
                      {previewImage.image?.childImageSharp ? (
                        <Img
                          className="relative"
                          fluid={previewImage.image.childImageSharp.fluid}
                        />
                      ) : (
                        <img
                          className="relative"
                          src={previewImage.image.publicURL}
                          alt="Preview"
                          style={{
                            width: `100%`
                          }}
                        />
                      )}
                      <div className="mask">
                        <div className="text-off-white uppercase">
                          <h3 className="primary-text f1">{key}</h3>
                          <h4 className="secondary-text b2">{artist.role}</h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </article>
              );
            })}
        </section>
      </Layout>

      <Footer />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "artist-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            role
            gallery {
              align
              image {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 5120, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              width
            }
            seoDescription
            seoKeywords
          }
        }
      }
    }
  }
`;
